import BackendError from '../common/backendError';
import { User } from '../common/model';

export class ConnectionError extends Error {}

enum Method {
    GET = 'GET',
    POST = 'POST',
}

enum GianniniEndpoint {
    Login = 'login',
    Create = 'create',
    Config = 'config',
    Grant = 'grant',
    Authorize = 'authorize',
    Context = 'context',
}

abstract class BaseClient {}

type Client<E extends string> = {
    methodForEndpoint: (endpoint: E) => string;
};

interface Giannini {
    host: string;
    port: string;
    path?: string;
}

type Endpoint<Response extends unknown, Payload extends unknown> = (
    p: Payload
) => Promise<Response | ConnectionError | BackendError>;

// type Eager<E extends (...args: any) => any, G = Parameters<E>> = (i: G) => E;

enum EagerStatus {
    Pending = 0,
    Success,
    Error,
}

const eager = <Payload, PromiseT>(
    e: (t: Payload) => Promise<PromiseT>
): ((t: Payload) => PromiseT) => {
    let status: EagerStatus = EagerStatus.Pending;
    let result: PromiseT;
    return (a: Payload) => {
        switch (status) {
            case EagerStatus.Pending:
                throw e(a).then(
                    (r) => {
                        status = EagerStatus.Success;
                        result = r;
                    },
                    (e) => {
                        status = EagerStatus.Error;
                        result = e;
                    }
                );
            case EagerStatus.Error:
                throw result;
            case EagerStatus.Success:
                return result;
        }
    };
};

type Empty = {};

export default class GianniniClient
    extends BaseClient
    implements Client<GianniniEndpoint> {
    public authorize: Endpoint<AuthResponse, AuthPayload>;
    public context: Endpoint<ContextResponse['context'], ContextPayload>;
    public create: Endpoint<NewAccountResponse, NewAccountPayload>;
    public configuration: Endpoint<ConfigResponse, ConfigPayload>;
    public grant: Endpoint<GrantResponse, GrantPayload>;
    public eager: {
        configuration: any;
    };
    constructor(private config: Giannini) {
        super();

        this.authorize = this.request(
            GianniniEndpoint.Authorize,
            async (r): Promise<AuthResponse> => await r.json(),
            async (error) => error
        );

        this.context = this.request(
            GianniniEndpoint.Context,
            async (r): Promise<ContextResponse['context']> =>
                ((await r.json()) as ContextResponse).context,
            async (error) => error
        );

        this.create = this.request(
            GianniniEndpoint.Create,
            async (r): Promise<NewAccountResponse> => await r.json(),
            async (error) => error
        );

        this.configuration = this.request(
            GianniniEndpoint.Config,
            async (r): Promise<ConfigResponse> => await r.json(),
            async (error) => error
        );

        this.grant = this.request(
            GianniniEndpoint.Grant,
            async (r): Promise<GrantResponse> => await r.json(),
            async (error) => error
        );

        const x = eager(this.configuration);
        this.eager = {
            configuration: x,
        };
    }

    private get uri(): string {
        const { host, port, path } = this.config;
        const url = new URL('http://localhost');
        url.port = port;
        url.host = host;
        url.pathname = path ?? '';
        return url.toString();
    }

    private request<P extends Empty, R extends unknown, E extends Error>(
        endpoint: GianniniEndpoint,
        handler: (response: Response) => Promise<R>,
        errors: (error: Error) => Promise<E>
    ): (payload: P) => Promise<R | ConnectionError | BackendError> {
        const method = this.methodForEndpoint(endpoint);
        const resource = new URL(`${this.uri}/${endpoint}`);
        return async function (payload) {
            if (method !== Method.POST) {
                Object.entries({ ...payload })
                    .filter(
                        (e): e is [string, string] =>
                            e.length === 2 &&
                            typeof e[0] === 'string' &&
                            typeof e[1] === 'string'
                    )
                    .forEach(([k, v]) => resource.searchParams.set(k, v));
            }
            try {
                const response = await fetch(resource.toString(), {
                    method,
                    headers: {
                        Origin: window.location.href,
                        // Allow: '*',
                        'Content-Type': 'application/json;charset=utf-8',
                        'Access-Control-Allow-Origin': '*',
                    },
                    mode: 'cors',
                    credentials: 'include',
                    ...(method !== Method.POST
                        ? {}
                        : { body: JSON.stringify(payload) }),
                });
                if (response.status >= 200 && response.status < 300) {
                    return await handler(response);
                } else {
                    throw new BackendError(await response.json());
                }
            } catch (error) {
                if (error.message === 'Failed to fetch') {
                    return new ConnectionError();
                }
                return await errors(error);
            }
        };
    }

    methodForEndpoint(endpoint: GianniniEndpoint): Method {
        switch (endpoint) {
            case GianniniEndpoint.Config:
            case GianniniEndpoint.Context:
                return Method.GET;
            default:
                return Method.POST;
        }
    }
}

interface Redirecting {
    redirect: string;
}

export interface AuthPayload {
    username: string;
    password: string;
    challenge?: string;
}
export interface AuthResponse extends Redirecting {
    user: User;
}

export interface ContextPayload {
    login?: string;
    consent?: string;
}

export interface ContextResponse {
    context: {
        name: string;
        logoUri?: string;
        consent?: {
            scope?: string[];
            audience?: string[];
        };
    };
}

export interface NewAccountPayload {
    username: string;
    email?: string;
    password: string;
    challenge?: string;
}

export interface NewAccountResponse {
    user: User;
    redirect?: string;
}

export type ConfigPayload = {};

export interface ConfigResponse {
    create: {
        email?: boolean;
    };
}

export interface GrantPayload {
    challenge: string;
    scope: string[];
}

export interface GrantResponse extends Redirecting {
    user: User;
}
