import React, { useMemo } from 'react';
import Giannini from './giannini';
import { ResourceManifest } from '../common/resources';

abstract class Clients {
    abstract giannini: Giannini;
}

abstract class ClientsManager extends Clients {
    abstract setConfig(m: ResourceManifest): void;
}

export class APIClients extends ClientsManager {
    constructor() {
        super();
        this.setConfig = this.setConfig.bind(this);

        this.giannini = new Giannini({
            host: 'localhost',
            port: '3001',
        });
    }

    public giannini: Giannini;

    public setConfig({ authBackend }: ResourceManifest): void {
        this.giannini = new Giannini({ ...authBackend });
    }
}

const context = React.createContext(new APIClients());

export const ClientsProvider: React.FC<{ resources?: ResourceManifest }> = ({
    children,
    resources,
}) => {
    const clients = useMemo(() => {
        const instance = new APIClients();
        if (resources) {
            console.log('Updating auth client config.');
            instance.setConfig(resources);
        }
        return instance;
    }, [resources]);
    return <context.Provider value={clients}>{children}</context.Provider>;
};

export const useClients = (): Clients => React.useContext(context);
