import React from 'react';
import { Helmet } from 'react-helmet-async';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { Page } from './page';
import { OAuthErrorPayload } from '../components/OAuthError';
import autobind from 'autobind-decorator';
import { User } from '../common/model';
import FinePrint from '../components/FinePrint';

type Props = {} & RouteComponentProps<{}, {}, { user: User }>;

class Terms extends React.Component<Props> implements Page {
    @autobind
    setError(e: OAuthErrorPayload): void {
        console.log(e);
    }

    render(): React.ReactElement {
        return (
            <TermsPage className={classnames('terms-page')}>
                <PageMeta />
                <h1>Terms of Service</h1>
                <p>
                    <em>Effective date: December 1st, 2020</em>
                </p>
                <FinePrint blocks={terms} />
            </TermsPage>
        );
    }
}

const PageMeta: React.FC = () => {
    const { t } = useTranslation();
    return (
        <Helmet>
            <title>{t('pages.terms.title')}</title>
        </Helmet>
    );
};

const TermsPage = styled.div`
    display: flex;
    flex-flow: column nowrap;
    background-color: #f0f0f0;
    overflow: hidden auto;
    max-width: 900px;
    padding: 20px;
    border-radius: 5px;
`;

export default Terms;

const terms = [
    {
        body: `The RecipeMagnet website located at recipemag.net (the "Website") and the various applets, extensions, plugins, and mobile apps (collectively the "Applications") that interface with it are copyrighted works belonging to Ermolay Romanov SP (referred to herein as "RecipeMagnet"). RecipeMagnet provides a platform that supports users, their families and friends with personalized insights and other information about food and food-related subjects. It also connects individual users between each other as well as to content creators, brands, and brick-and-mortar businesses. Such services may be accessed through various digital and analog tools (collectively the "Services"). THIS TERMS OF SERVICE AGREEMENT ("AGREEMENT") SETS FORTH THE LEGALLY BINDING TERMS FOR YOUR USE OF THE SERVICES. THIS AGREEMENT IS ACCEPTED BY YOUR ACCESSING AND/OR USE OF THE SERVICES. YOU MAY NOT ACCESS AND/OR USE THE SERVICES OR ACCEPT THE AGREEMENT IF YOU ARE NOT 18 YEARS OLD. BY ACCESSING AND/OR USING THE SERVICES, YOU REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO THE AGREEMENT AND TO ABIDE BY ALL OF THE TERMS AND CONDITIONS SET FORTH HEREIN. IF YOU DO NOT AGREE WITH ALL PROVISIONS OF THIS AGREEMENT, PLEASE DO NOT ACCESS AND/OR USE THE SERVICES.`,
    },
    {
        comments: [
            "PLEASE REVIEW RecipeMagnet'S PRIVACY POLICY. THE TERMS OF THE PRIVACY POLICY ARE INCORPORATED INTO, AND CONSIDERED A PART OF, THIS AGREEMENT.",
        ],
    },
    {
        title: 'CERTAIN DISCLAIMERS',
        body: `ALL INFORMATION CONTAINED ON THE SERVICES (INCLUDING THE WEBSITE AND Applications), INCLUDING RECIPES, NUTRITION INFORMATION, COOKING PROGRAMS, RECOMMENDATIONS AND INFORMATION RELATING TO FOOD AND/OR HEALTH AND NUTRITION IS FOR INFORMATIONAL PURPOSES ONLY AND NOT PROVIDED AS MEDICAL ADVICE. RecipeMagnet DOES NOT ASSUME ANY LIABILITY FOR FOODBORNE ILLNESS OR FOR INACCURACIES OR MISSTATEMENTS ABOUT COOKING PROGRAMS OR PRODUCTS, WHETHER BASED ON THIRD PARTY INFORMATION OR ON RATINGS GENERATED BY THE Applications AND/OR SERVICES. YOU SHOULD CAREFULLY READ ALL INFORMATION PROVIDED BY THE MANUFACTURERS OF THE FOOD PRODUCTS OR IN THE ACTUAL PRODUCT PACKAGING AND LABELS, INCLUDING FOR FOOD ALLERGEN AND CONTACT INFORMATION, NUTRIENT CONTENT, QUALIFIED HEALTH CLAIMS, AND DISCLAIMERS REGARDING FOOD SUPPLEMENT STRUCTURE AND FUNCTION CLAIMS, BEFORE USING OR CONSUMING A PRODUCT. FOR ADDITIONAL INFORMATION ABOUT A PRODUCT, PLEASE CONTACT THE MANUFACTURER.

    INFORMATION ON THE SERVICES IS NOT INTENDED AS A SUBSTITUTE FOR THE ADVICE PROVIDED BY A HEALTHCARE PROFESSIONAL. YOU SHOULD NOT USE INFORMATION ON THE SERVICES FOR THE DIAGNOSIS OR TREATMENT OF A HEALTH PROBLEM OR DISEASES. YOU SHOULD ALWAYS CONSULT YOUR HEALTHCARE PROVIDER BEFORE MAKING ANY DIETARY OR NUTRITIONAL CHANGES.

    IN ADDITION TO ALL OTHER LIMITATIONS AND DISCLAIMERS IN THIS AGREEMENT, RecipeMagnet DISCLAIMS ANY LIABILITY OR LOSS IN CONNECTION WITH THE CONTENT PROVIDED ON THE SERVICES.
    `,
    },
    {
        title: 'Accounts',
        items: [
            `Account Creation. In order to use certain features of the Services, you must register for an account ("RecipeMagnet Account") on the Website and provide certain information about yourself as prompted by the Website registration form. You may be asked for additional information as you continue to use the Services. You represent and warrant that: (a) all required registration information you submit is truthful and accurate; (b) you will maintain the accuracy of such information; and (c) your use of the Services does not violate any applicable law or regulation or the terms of this Agreement. You may delete your RecipeMagnet Account at any time, for any reason, by following the instructions on the Website. RecipeMagnet may suspend or terminate your RecipeMagnet Account in accordance with Section 11.`,
            `User Profile. Your RecipeMagnet Account allows you to create a personal profile ("Profile"). You are not required to complete the Profile, but the more complete it is, the more RecipeMagnet will be able to provide specific information for your diet and nutrition concerns.`,
            'Account Responsibilities. You are responsible for maintaining the confidentiality of your RecipeMagnet Account login information and are fully responsible for all activities that occur under your RecipeMagnet Account. You agree to immediately notify RecipeMagnet of any unauthorized use, or suspected unauthorized use of your RecipeMagnet Account or any other breach of security. RecipeMagnet cannot and will not be liable for any loss or damage arising from your failure to comply with the above requirements. We will protect your RecipeMagnet Account information in accordance with the Privacy Policy.',
        ],
    },
    {
        title: 'SERVICE LICENSE AND INFORMATION',
        items: [
            `License. Subject to the terms of this Agreement, RecipeMagnet grants you a non-transferable, non-exclusive, license (without the right to sublicense) to (i) use the Services solely for your personal, non-commercial purposes, and (ii) install and use the Applications, solely on your own handheld mobile device (e.g., smartphone, tablet or similar) and solely for your personal, non-commercial purposes.`,
            `Certain Restrictions. The rights granted to you in this Agreement are subject to the following restrictions: (a) you shall not license, sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Services; (b) you shall not modify, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Services; (c) you shall not access the Services in order to build a similar or competitive service; and (d) except as expressly stated herein, no part of the Services may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means. Any future release, update, or other addition to functionality of the Services shall be subject to the terms of this Agreement. All copyright and other proprietary notices on any Website content must be retained on any copies.`,
            `Modification. RecipeMagnet reserves the right, at any time, to modify, suspend, or discontinue the Services (including the Website and Applications) or any part thereof with or without notice. You agree that RecipeMagnet will not be liable to you or to any third party for any modification, suspension, or discontinuance of the Services or any part thereof.`,
            `Ownership. You acknowledge that all the intellectual property rights, including copyrights, patents, trademarks, and trade secrets, in the Services (including the Website and Applications but excluding any User Content) are owned by RecipeMagnet or RecipeMagnet's licensors. The provision of the Services does not transfer to you or any third party any rights, title or interest in or to such intellectual property rights. RecipeMagnet and its suppliers reserve all rights not granted in this Agreement.`,
        ],
    },

    {
        title: 'ACCESS TO SERVICES',
        items: [
            `Limitations. Your right to access and use the Services is personal to you and is not transferable by you to any other person or entity. You are only entitled to access and use the Sites and Services for lawful purposes. In order for the Services to function effectively, you must also keep your Registration and Account Information up to date and accurate. If you do not do this, the accuracy and effectiveness of the Services will be affected.`,
            `Interruptions. Your access and use of Services may be interrupted from time to time for any of several reasons, including, without limitation, the malfunction of equipment, periodic updating, maintenance or repair of the Services or other actions that RecipeMagnet, in its sole discretion, may elect to take. In no event will RecipeMagnet be liable to any party for any loss, cost, or damage that results from any scheduled or unscheduled downtime.`,
            `Remedies. Your sole and exclusive remedy for any failure or non-performance of the Services, including any associated software or other materials supplied in connection with such services, shall be for RecipeMagnet to use commercially reasonable efforts to effectuate an adjustment or repair of the applicable service.`,
            `Purchase History. The Services may involve collection of data related to a user’s use of the Service. If so, the collection and use of such data will be governed by RecipeMagnet’s current Privacy Policy.`,
        ],
    },
    {
        title: 'USER CONTENT',
        items: [
            `User Content. Some features of the Services may enable users to voluntarily submit or share User Content with RecipeMagnet or other users. To the extent that such features of the Services are provided, this Section 6 applies. "User Content" of a user means any and all information and content that such user submits to, or uses with, the Services (e.g., content in the user's profile or forum post). You are solely responsible for your User Content. You assume all risks associated with use of your User Content, including any reliance on its accuracy, completeness or usefulness by others, or any disclosure of your User Content that makes you or any third party personally identifiable. You hereby affirm, represent, and warrant that (i) you own, or have the necessary licenses, rights, and/or consents to use your User Content with the Services as described herein and (ii) your User Content does not violate the Acceptable Use Policy (defined below). You may not state or imply that your User Content is in any way provided, sponsored or endorsed by RecipeMagnet. Because you alone are responsible for your User Content (and not RecipeMagnet), you may expose yourself to liability if, for example, your User Content violates the Acceptable Use Policy (see Section 7 below). RecipeMagnet is not obligated to backup any User Content and User Content may be deleted at anytime. You are solely responsible for creating backup copies of your User Content if you desire. We will protect User Content in accordance with the Privacy Policy.`,
            `License. By using your User Content with the Services, you automatically grant, and you represent and warrant that you have the right to grant, to RecipeMagnet an irrevocable, nonexclusive, royalty-free and fully paid, worldwide license to reproduce, distribute, publicly display and perform, prepare derivative works of, incorporate into other works, and otherwise use your User Content, and to grant sublicenses of the foregoing for any purpose.`,
            `Feedback. RecipeMagnet will treat any feedback or suggestions you provide to RecipeMagnet as non-confidential and non-proprietary. Thus, in the absence of a written agreement with RecipeMagnet to the contrary, you agree that you will not submit to RecipeMagnet any information or ideas that you consider to be confidential or proprietary. You agree that you grant the full scope of License provided in section 6.2, above, to RecipeMagnet in connection with any feedback or suggestions you provide.`,
        ],
    },
    {
        title: 'ACCEPTABLE USE POLICY.',
        body: `The following sets forth RecipeMagnet's "Acceptable Use Policy", which may be updated from time to time:`,
        items: [
            `You agree not to use the Services to collect, upload, transmit, display, or distribute any User Content (a) that violates any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; (b) that is unlawful, harassing, abusive, tortious, threatening, harmful, abusive, invasive of another's privacy, vulgar, defamatory, false, intentionally misleading, trade libelous, pornographic, obscene, patently offensive (e.g., material that promotes racism, bigotry, hatred, or physical harm of any kind against any group or individual) or otherwise objectionable material of any kind or nature or which is harmful to minors in any way; or (c) in violation of any law, regulation, or obligations or restrictions imposed by any third party.`,

            `In addition, you agree not to use the Services to: (a) upload, transmit, or distribute any computer viruses, worms, or any software intended to damage or alter a computer system or data; (b) send unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other form of duplicative or unsolicited messages, whether commercial or otherwise; (c) harvest, collect, gather or assemble information or data regarding other users, including e-mail addresses, without their consent; (d) interfere with or disrupt servers or networks connected to the Services or violate the regulations, policies or procedures of such networks or otherwise interferes with our operation or provision of the Services; (e) attempt to gain unauthorized access to the Services, other computer systems or networks connected to or used together with the Services, through password mining or other means; or (f) harass or interfere with another user's use and enjoyment of the Services.`,

            `We reserve the right (but have no obligation under this Agreement) to review any User Content, investigate, and/or take appropriate action against you, in our sole discretion, if you violate the Acceptable Use Policy or any other provision of this Agreement, including removing or modifying your User Content, suspending or terminating your RecipeMagnet Account in accordance with Section 12, and/or reporting you to law enforcement authorities. We may also remove or modify your User Content if it, in our sole judgment, violates the rights of, harms, or threatens the safety of any other person, violates section 7.1, above, or creates liability for us or any other person. In order to cooperate with legitimate governmental requests, subpoenas or court orders, or to protect our business and customers, we may access and disclose any information consider necessary or appropriate, including your RecipeMagnet Account username and password, IP address and traffic information, usage history, and your User Content.`,
        ],
    },
    {
        title: 'APP STORES, THIRD PARTY SITES & ADS; OTHER USERS',
        items: [
            `App Stores. You acknowledge and agree that the availability of the Services is dependent on the third party from which you received the Applications, e.g., the Apple iPhone store website ("App Store"). You acknowledge that this Agreement is between you and RecipeMagnet and not with the App Store. RecipeMagnet, not the App Store, is solely responsible for the Applications, the content thereof, maintenance, support services, and warranty therefor, and addressing any claims relating thereto (e.g., product liability, legal compliance, or intellectual property infringement). You agree to pay all fees charged by the App Store in connection with the Applications. You agree to comply with, and your license to use the Applications is conditioned upon your compliance with, all applicable agreements, terms of use/service, and other policies of the App Store. You acknowledge that the App Store (and its subsidiaries) is a third party beneficiary of this Agreement and will have the right to enforce this Agreement.`,

            `Apple App Store Notices. The following additional terms and conditions apply to you if you are using a Applications from the Apple App Store.`,

            `1. Acknowledgement: RecipeMagnet and you acknowledge that this Agreement is concluded between RecipeMagnet and you only, and not with Apple. RecipeMagnet, not Apple, is solely responsible for the Applications and the content thereof. To the extent this Agreement provides for usage rules for the Applications that are less restrictive than the Usage Rules set forth for Applications in, or otherwise is in conflict with, the App Store Terms of Service, the more restrictive or conflicting Apple term applies.`,

            `2. Scope of License: The license granted to you for the Applications is limited to a non-transferable license to use the Applications on an iPhone OS Product that you own or control and as permitted by the Usage Rules set forth in the App Store Terms of Service.`,

            `3. Maintenance and Support: RecipeMagnet is solely responsible for providing any maintenance and support services with respect to the Applications, as specified in this Agreement, or as required under applicable law. RecipeMagnet and you acknowledge that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the Applications.`,

            `4. Warranty: RecipeMagnet is solely responsible for any software product warranties, whether express or implied by law, to the extent not effectively disclaimed. In the event of any failure of the Applications to conform to any applicable warranty, you may notify Apple, and Apple will refund the purchase price for the Applications to you; and to the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the Applications, and any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be RecipeMagnet's sole responsibility.`,

            `5. Product Claims: RecipeMagnet and you acknowledge that RecipeMagnet, not Apple, is responsible for addressing any claims of you or any third party relating to the Applications or your possession and/or use of that Applications, including, but not limited to: (i) product liability claims; (ii) any claim that the Applications fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or similar legislation. This Agreement does not limit RecipeMagnet's liability to you beyond what is permitted by applicable law.`,

            `6. Intellectual Property Rights: RecipeMagnet and you acknowledge that, in the event of any third party claim that the Applications or your possession and use of that Applications infringes that third party's intellectual property rights, RecipeMagnet, not Apple, will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim.`,

            `7. Legal Compliance: You represent and warrant that (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a "terrorist supporting" country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.`,

            `8. Name and Address. Any questions, complaints, or claims about this Agreement should be addressed to support@recipemag.net`,

            `9. Third Party Terms of Agreement: You must comply with applicable third party terms of agreement when using the Applications.`,

            `10. Third Party Beneficiary: RecipeMagnet and you acknowledge and agree that Apple, and Apple's subsidiaries, are third party beneficiaries of this Agreement, and that, upon your acceptance of the terms and conditions of this Agreement, Apple will have the right (and will be deemed to have accepted the right) to enforce this Agreement against you as a third party beneficiary thereof.`,

            `Third Party Sites/Linked Sites. The Services might contain links to third party websites and advertisements for third parties (collectively, "Third Party Sites & Ads"). Such Third Party Sites & Ads are not under the control of RecipeMagnet and RecipeMagnet is not responsible for any Third Party Sites & Ads. If provided by RecipeMagnet, these Third Party Sites & Ads are only a convenience and RecipeMagnet does not review, approve, monitor, endorse, warrant, or make any representations with respect to Third Party Sites & Ads. You use all Third Party Sites & Ads at your own risk. When you link to a Third Party Site, the applicable service provider's terms and policies, including privacy and data gathering practices govern. We encourage you read the terms of use of such other websites, and to make whatever investigation you feel necessary or appropriate before proceeding with any transaction with any third party.`,

            `Release. You hereby release us, our officers, employees, agents and successors from claims, demands any and all losses, damages, rights, claims, and actions of any kind including personal injuries, death, and property damage, that is either directly or indirectly related to or arises from any interactions with or conduct of any App Store, any other Service users, or any Third Party Sites & Ads. IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE SECTION 1542 IN CONNECTION WITH THE FOREGOING, WHICH STATES: "A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH, IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR."`,
        ],
    },
    {
        title: 'DISCLAIMERS',
        body: `THE SERVICES (INCLUDING THE WEBSITE, Applications, AND ANY RecipeMagnet CONTENT) ARE PROVIDED "AS-IS" AND AS AVAILABLE AND WE (AND OUR SUPPLIERS, PARENTS, AFFILIATES, AND RELATED COMPANIES) EXPRESSLY DISCLAIM ANY WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING THE WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT. WE (AND OUR SUPPLIERS, PARENTS, AFFILIATES, AND RELATED COMPANIES) MAKE NO WARRANTY THAT THE SERVICES (INCLUDING THE WEBSITE, Applications, OR RecipeMagnet CONTENT): (A) WILL MEET YOUR REQUIREMENTS; (B) WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; OR (C) WILL BE ACCURATE, RELIABLE, COMPLETE, LEGAL, OR SAFE. WE (AND OUR SUPPLIERS, PARENTS, AFFILIATES, AND RELATED COMPANIES) MAKE NO WARRANTY REGARDING THE THIRD PARTY SITES & ADS. PLEASE ALSO REVIEW THE SPECIFIC DISCLAIMERS IN SECTION 2.
    SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.`,
    },
    {
        title: 'LIMITATION ON LIABILITY',
        body: `IN NO EVENT SHALL WE (AND OUR SUPPLIERS) BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM THIS AGREEMENT OR YOUR USE OF, OR INABILITY TO USE, THE SERVICES (INCLUDING THE WEBSITE, Applications, OR RecipeMagnet CONTENT), OR THIRD PARTY SITES & ADS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE SERVICES, AND THIRD PARTY SITES & ADS ARE AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA RESULTING THEREFROM. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR (AND OUR SUPPLIERS') LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO THIS AGREEMENT OR THE SERVICES, FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE GREATER OF (A) FIFTY US DOLLARS ($50) OR (B) AMOUNTS YOU'VE PAID RecipeMagnet IN THE PRIOR 12 MONTHS (IF ANY).
    SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.
    `,
    },
    {
        title: 'INDEMNITY',
        body: `You agree to indemnify and hold RecipeMagnet, its parents, subsidiaries, affiliates, any related companies, its suppliers, licensors and partners, and the officers, directors, employees, agents and representatives of each of them harmless, including costs and attorneys' fees, from any claim or demand made by any third party due to or arising out of (i) your use of the Services, (ii) your User Content, or (iii) your violation of this Agreement. RecipeMagnet reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us and you agree to cooperate with our defense of these claims. You agree not to settle any matter without the prior written consent of RecipeMagnet. RecipeMagnet will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.`,
    },
    {
        title: 'TERM AND TERMINATION',
        body:
            'Subject to this Section, this Agreement will remain in full force and effect while you use the Services. We may (a) suspend your rights to use the Services (including your RecipeMagnet Account) or (b) terminate this Agreement, at any time for any reason at our sole discretion, including for any use of the Services in violation of this Agreement or if we believe you are younger than 18. Upon termination of this Agreement, your RecipeMagnet Account and right to access and use the Services will terminate immediately. You understand that any termination of your RecipeMagnet Account involves deletion of your User Content associated therewith from our live databases. RecipeMagnet will not have any liability whatsoever to you for any termination of this Agreement, including for termination of your RecipeMagnet Account or deletion of your User Content. Even after this Agreement is terminated, the following provisions of this Agreement will remain in effect: Sections 2, 4.2-4.4, 6–14.',
    },
    {
        title: 'COPYRIGHT POLICY',
        body: `RecipeMagnet respects the intellectual property of others and asks that users of our services do the same. In connection with our services, we have adopted and implemented a policy respecting copyright law that provides for the removal of any infringing materials and for the termination, in appropriate circumstances, of users of our online services who are repeat infringers of intellectual property rights, including copyrights. If you believe that one of our users is, through the use of our services, unlawfully infringing the copyright(s) in a work, and wish to have the allegedly infringing material removed, the following information in the form of a written notification (pursuant to 17 U.S.C. § 512(c)) must be provided to our designated Copyright Agent:

    your physical or electronic signature;
    identification of the copyrighted work(s) that you claim to have been infringed;
    identification of the material on our services that you claim is infringing and that you request us to remove;
    sufficient information to permit us to locate such material;
    your address, telephone number, and e-mail address;
    a statement that you have a good faith belief that use of the objectionable material is not authorized by the copyright owner, its agent, or under the law; and
    a statement that the information in the notification is accurate, and under penalty of perjury, that you are either the owner of the copyright that has allegedly been infringed or that you are authorized to act on behalf of the copyright owner.
    Please note that, pursuant to 17 U.S.C. § 512(f), any misrepresentation of material fact (falsities) in a written notification automatically subjects the complaining party to liability for any damages, costs and attorney's fees incurred by us in connection with the written notification and allegation of copyright infringement.
`,
        // comments: [` The designated Copyright Agent for RecipeMagnet is: Eugenio Garcia. Phone: 650-437-7253. Email: privacy@RecipeMagnet.com.`],
    },
    {
        title: 'Section 14: GENERAL',
        body: `
14.1. Changes to Terms of Service. This Agreement is subject to occasional revision, and if we make any substantial changes, we may notify you by sending you an e-mail to the last e-mail address you provided to us (if any) and/or by prominently posting notice of the changes on our Website. Any material changes to this agreement will be effective upon the earlier of thirty (30) calendar days following our dispatch of an e-mail notice to you (if applicable) or thirty (30) calendar days following our posting of notice of the changes on our Website or Applications. These changes will be effective immediately for new users of our Website or Applications. You are responsible for providing us with your most current e-mail address. In the event that the last e-mail address that you have provided us is not valid, or for any reason is not capable of delivering to you the notice described above, our dispatch of the e-mail containing such notice will nonetheless constitute effective notice of the changes described in the notice. Continued use of our Services following notice of such changes shall indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes.

14.2. Governing Law. This Agreement shall be governed by the laws of the State of New York without giving effect to any conflict of laws principles that may provide the application of the law of another jurisdiction. You agree to submit to the personal jurisdiction of the state courts and federal courts located within United States for the purpose of litigating all such claims or disputes. Notwithstanding the foregoing, we may seek injunctive or other equitable relief to protect its intellectual property rights in any court of competent jurisdiction.

14.3. Entire Agreement. This Agreement (which includes the Privacy Policy and any other rules posted on the Website) constitutes the entire agreement between you and us regarding the use of the Services. Our failure to exercise or enforce any right or provision of this Agreement shall not operate as a waiver of such right or provision. The section titles in this Agreement are for convenience only and have no legal or contractual effect. The word including means including without limitation. If any provision of this Agreement is, for any reason, held to be invalid or unenforceable, the other provisions of this Agreement will be unimpaired and the invalid or unenforceable provision will be deemed modified so that it is valid and enforceable to the maximum extent permitted by law. This Agreement, and your rights and obligations herein, may not be assigned, subcontracted, delegated, or otherwise transferred by you without RecipeMagnet's prior written consent, and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be null and void. The terms of this Agreement shall be binding upon assignees.

14.4 Copyright/Trademark Information. Copyright © 2020, RecipeMagnet. All rights reserved. All trademarks, logos and service marks ("Marks") displayed on the Services are our property or the property of other third parties. You are not permitted to use these Marks without our prior written consent or the consent of such third party which may own the Marks.

`,
    },
    {
        comments: [
            'If you have questions about this Agreement, you may write to us at privacy@recipemag.net',
            'This Agreement was last revised on December 1st, 2020.',
        ],
    },
];
