// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/app';
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics';
import config from '../firebase_config.json';

export default async function (): Promise<void> {
    firebase.initializeApp(config);
    console.log(firebase.apps);
}
