export interface ErrorWrapper {
    error: string;
}

export default class BackendError extends Error {
    constructor(data: ErrorWrapper) {
        super(data.error);
        this.name = 'BackendError';
    }
}
