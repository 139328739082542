import React from 'react';
import styled from 'styled-components';
import { Route, Switch } from 'react-router-dom';

import Home from './Home';
import Terms from './Terms';
import Privacy from './Privacy';

const routes = [
    <Route key={'home'} component={Home} path="/" exact />,
    <Route key={'terms'} component={Terms} path="/terms-of-service" exact />,
    <Route key={'policy'} component={Privacy} path="/privacy-policy" exact />,
    <Route key={'fallback'} component={Home} />,
];

const PageWrapper = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden auto;
`;

export default function Pages(): React.ReactElement {
    return (
        <PageWrapper>
            <Switch>{routes}</Switch>
        </PageWrapper>
    );
}
