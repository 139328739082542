import styled from 'styled-components';

export const StackWrapper = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin: 0 auto;
`;

export const Main = styled.div.attrs(({ error }: { error: boolean }) => {
    return { error };
})`
    overflow: hidden;
    display: flex;
    background: #fbfbfb;
    padding: 40px 40px 40px 40px;
    border-radius: 10px;
    box-shadow: ${({ error }) => (error ? '#cc6544' : '#122069')} 0px 0px 20px
        15px;
    flex-flow: column nowrap;
    justify-content: center;
    transition: all ease-out 250ms;

    & > *:not(:last-child) {
        margin-bottom: 15px;
    }
`;

export const Extra = styled.div`
    flex: 0 0 auto;
    overflow: hidden;
    width: min-content;
    min-width: 100%;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    text-align: center;

    padding: 10px 5px;
    margin-top: 30px;
    border-radius: 6px;
    background: aliceblue;

    line-height: 1.1em;
    font-size: 0.8rem;
`;

export { StackWrapper as default };
