import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ResourceManifest } from './common/resources';
import { init } from './services/internationalization';
import firebase from './services/firebase';

(async (d: Document) => {
    const root = d.getElementById('root');
    if (!root) {
        ReactDOM.render(
            <div>Unable to load the content at this time.</div>,
            document.body
        );
        return;
    }

    try {
        await init;
        await firebase();
    } catch (e) {
        console.error(e);
    }

    let resources: ResourceManifest | undefined;
    try {
        const configURL = root.getAttribute('data-resources');
        if (configURL) {
            const data = await fetch(configURL, {});
            const json = await data.json();
            resources = json;
            // if (json['authBackend']) {
            // }
        }
    } catch (e) {
        console.error(e.message);
    }

    ReactDOM.render(<App resources={resources} />, root);
})(document);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
