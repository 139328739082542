import React, { Suspense } from 'react';
import styled from 'styled-components';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { ResourceManifest } from './common/resources';
import Navigation from './components/Navigation';
import Pages from './pages';

import './services/internationalization';
import { ClientsProvider } from './services/clientsContext';

const Application: React.FC<{ resources?: ResourceManifest }> = ({
    resources,
}) => {
    return (
        <Suspense fallback={'loading'}>
            <Wrapper className="application">
                <HelmetProvider>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>RecipeMagnet</title>
                        {resources?.baseUri ? (
                            <link
                                rel="canonical"
                                href={`${resources.baseUri}${process.env.PUBLIC_URL}`}
                            />
                        ) : null}
                    </Helmet>
                    <ClientsProvider resources={resources}>
                        <BrowserRouter basename={process.env.PUBLIC_URL}>
                            <Navigation />
                            <Pages />
                        </BrowserRouter>
                    </ClientsProvider>
                </HelmetProvider>
            </Wrapper>
        </Suspense>
    );
};

const Wrapper = styled.div`
    width: 100%;
    max-width: 100vw;
    max-height: 100vh;
    overflow: hidden;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin: 20px;
    padding-bottom: 40px;
`;

export default Application;
