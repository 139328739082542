import React from 'react';
import { Helmet } from 'react-helmet-async';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { Page } from './page';
import FinePrint from '../components/FinePrint';
import autobind from 'autobind-decorator';

type Props = {} & RouteComponentProps;

class Privacy extends React.Component<Props> implements Page {
    @autobind
    render(): React.ReactElement {
        return (
            <PrivacyPage className={classnames('privacy-page')}>
                <PageMeta />
                <h1>Privacy Policy</h1>
                <p>
                    <em>Effective date: December 1st, 2020</em>
                </p>
                <FinePrint blocks={privacy} />
            </PrivacyPage>
        );
    }
}

const PageMeta: React.FC = () => {
    const { t } = useTranslation();
    return (
        <Helmet>
            <title>{t('pages.privacy.title')}</title>
        </Helmet>
    );
};

const PrivacyPage = styled.div`
    display: flex;
    flex-flow: column nowrap;
    background-color: #f0f0f0;
    overflow: hidden auto;
    max-width: 900px;
    padding: 20px;
    border-radius: 5px;
`;

export default Privacy;

const privacy = [
    {
        body: `Thank you for using our products and visiting www.recipemag.net. This privacy policy tells you how we use personal information collected at this site. Please read this privacy policy before using the site or submitting any personal information. By using the application, you are accepting the practices described in this privacy policy. These practices may be changed. You are encouraged to review the privacy policy whenever you engage the application to make sure that you understand how any personal information you provide will be used.`,
        comments: [
            `Note: the privacy practices set forth in this privacy policy are for RecipeMagnet only.`,
        ],
    },
    {
        title: `Collection of Information`,
        body: `We collect personally identifiable information, like names, postal addresses, email addresses, etc., when voluntarily submitted by our users. The information you provide is used to fulfill your specific requests on the site. This information is used to fulfill your specific requests and includes adding to email/mailing lists. All emails and correspondences from will allow you to opt out of further mailings.`,
        //We will collect during the duration of our contests user details through user intent; trading, polling, video streaming and page views in order to create a process to better serve our partners and create monetary incentive for our users. Unlike most sites on the internet who collect and distribute information, RecipeMagnet welcomes the user into the process and uses the ability to collect and distribute useful information and reward those users with monetary rewards via their successful completion of a RecipeMagnet contest.`,
    },

    {
        title: 'Cookie/Tracking Technology',
        body: `The Site may use cookie and tracking technology depending on the features offered. Cookie and tracking technology are useful for gathering information such as browser type and operating system, tracking the number of visitors to the Site, and understanding how visitors use the Site. Cookies can also help customize the Site for visitors. Personal information cannot be collected via cookies and other tracking technology, however, if you previously provided personally identifiable information, cookies may be tied to such information. Aggregate cookie and tracking information may be shared with third parties.
`,
        comments: [
            `Additional Mobile App Information; we also may collect mobile device information like operating system and hardware type, numbers or codes that are unique to your particular device, device state information, default device language, the location of your device, and app usage information.
`,
        ],
    },
    {
        title: 'Distribution of Information',
        body: `We may share information with governmental agencies or other companies assisting us in fraud prevention or investigation. We may do so when: (1) permitted or required by law; or, (2) trying to protect against or prevent actual or potential fraud or unauthorized transactions; or, (3) investigating fraud which has already taken place. The information is not provided to these companies for marketing purposes.`,
    },
    {
        title: `How RecipeMagnet Uses The Information`,
        items: [
            `Your personal information will be used to operate, maintain, and provide to you the features and functionality of the Service, including but not limited to the following: (a) providing you our services, including our platform, including the processing your actions on the platform (b) processing and responding to inquiries; (c) alerting you to new Service features, special events, products and services, or certain third-party products or services in which we think you will be interested; and (d) enforcing the legal terms that govern your use of the Service.`,
            `Your information may be anonymized or aggregated for research and related purposes to better understand and enhance the product. Anonymous and/or aggregated data does not identify you, and we may use and disclose such information without limitation.`,
            `We may use your information to contact you for both marketing and non-marketing or administrative purposes (such as notifying you of significant changes in the Service or for customer service purposes) relating to RecipeMagnet.`,
            `We use your information to improve the quality and design of RecipeMagnet and to create new features, promotions, functionality, and services, such as by storing, tracking, and analyzing User preferences and trends.`,
            `We may use cookies, clear gifs, log file information and mobile app information for purposes such as (a) remembering information so that you will not have to re-enter it during your visit or the next time you visit RecipeMagnet, and helping users navigate and communicate with the Service (b) providing custom, personalized content and information; (c) sending advertisements (including display media) to you when you visit our website or other websites, and monitoring the effectiveness of our marketing campaigns; (d) monitoring aggregate metrics such as total number of visitors, pages viewed, (e) tracking your entries, submissions, and status in promotions, sweepstakes, and contests and (f) monitoring for or prevent fraud, or blocking inappropriate access to the website.`,
        ],
    },
    {
        title: `When RecipeMagnet Shares Information`,
        body: `General Uses. Our subsidiaries, affiliated companies, agents, or other businesses or persons that help us provide and operate the Service may have authorized access to your information (such as name or email address) for the purpose of processing such information on our behalf. We may share your personal information with third parties for their commercial or marketing use with your consent, or as part of a specific program or feature for which you will have the ability to opt-in or opt-out. We may disclose personally identifiable information in response to your requests for customer service.`,
        comments: [
            `Your Profile Information. Any information you provide on your profile page may be publicly visible. It may include, for instance, your username, the date you became a member, your user statistics and information you shared publicly, and (if you’ve uploaded one) your profile picture.`,
            `Other Situations That May Arise. We also may share personal information in other situations with your consent. In addition, RecipeMagnet reserves the right to disclose personal information that RecipeMagnet believes, in good faith, is appropriate or necessary to (a) enforce our Terms and Conditions or other posted policies (b) take precautions against liability or harm, (c) investigate and respond to third-party claims or allegations, or respond to a subpoena, legal order, or official request, (d) protect the security or integrity of our Service, and to protect the rights, property, or safety of RecipeMagnet, our users or any other person. Information also may be shared as described in the Merger, Sale or Bankruptcy section below.`,
        ],
    },

    // Sponsored Contests

    // RecipeMagnet will host contests that will invite brand sponsors to offer rewards and prizes to participants. It is through these partnerships that allow for RecipeMagnet to offer incentives associated with each contest. Through these partnerships RecipeMagnet may offer user information collected to better create experiences and incentives to brands to continue the funding of prize pools for our users. RecipeMagnet will use discretion and care when creating relationships that will prioritize the benefit to our user base.

    {
        title: `Commitment to Data Security`,
        body:
            'Your personally identifiable information is kept secure. Only authorized employees, agents and contractors (who have agreed to keep information secure and confidential) have access to this information. All emails and newsletters from this site allow you to opt out of further mailings.',
    },
    {
        title: 'Your Information Choices',
        body: `You may, of course, decline to submit personal information through RecipeMagnet, in which case RecipeMagnet may not be able to provide certain services to you.`,
    },
    {
        title: 'Data Security',
        body: `RecipeMagnet uses commercially reasonable physical, managerial, and technical safeguards to preserve the integrity and security of your personal information. As no such methods are foolproof, we cannot, however, with certainty ensure or warrant the security of any information you transmit to RecipeMagnet and you do so at your own risk.`,
    },
    {
        title: 'Merger, Sale, or Bankruptcy',
        body: `The event that RecipeMagnet is involved in a merger, acquisition, sale, bankruptcy, insolvency, reorganization, receivership, assignment for the benefit of creditors, or the application of laws or equitable principles affecting creditors' rights generally, or other change of control, we may transfer as an asset your personal asset to the acquiring or successor entity.`,
    },

    {
        title: `Your California Privacy Rights`,
        body: `If you reside in California, you may request certain general information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please email privacy@recipemag.net`,
    },
    {
        title: `Changes and updates`,
        body: `This Privacy Policy may be revised periodically and this will be reflected by the "Last updated” date above. Please revisit this page to stay aware of any changes. Your continued use of the Service constitutes your agreement to this Privacy Policy and any future revisions.`,
    },
    {
        title: `Contact Information`,
        body: `Please contact RecipeMagnet with any questions or comments about this Privacy Policy at:

e-mail: support@recipemag.net `,
    },
];
