import * as React from 'react';
import styled from 'styled-components';

interface EditorialBlock {
    title?: string;
    body?: string;
    comments?: string[];
    items?: string[];
}

const FinePrint: React.FC<{ blocks: EditorialBlock[] }> = ({ blocks }) => {
    return (
        <React.Fragment>
            {blocks.map(({ title, body, comments, items }, i) => (
                <React.Fragment key={i}>
                    {title ? <Title>{title}</Title> : null}
                    {body ? <Body>{body}</Body> : null}
                    {comments ? (
                        <p>
                            {comments.map((c, j) => (
                                <React.Fragment key={j}>
                                    <span>{c}</span>
                                    <br />
                                    <br />
                                </React.Fragment>
                            ))}
                        </p>
                    ) : null}
                    {items ? (
                        <ol>
                            {items.map((c, j) => (
                                <li key={j}>{c}</li>
                            ))}
                        </ol>
                    ) : null}
                </React.Fragment>
            ))}
        </React.Fragment>
    );
};

const Body = styled.p`
    margin: 10px 0;
`;
const Title = styled.h3`
    margin: 12px 0 6px 0;
`;

export default FinePrint;
