import React from 'react';
import { Helmet } from 'react-helmet-async';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
// import styled from 'styled-components';
import { Link, RouteComponentProps } from 'react-router-dom';
import StackWrapper, { Main } from '../components/Stack';
import { Page } from './page';

class Home extends React.Component<RouteComponentProps> implements Page {
    componentDidMount(): void {
        // console.log(this.props);
    }

    render(): React.ReactElement {
        return (
            <div className={classnames('home-page')}>
                <PageMeta />
                <StackWrapper>
                    <Main>
                        <Link to="/terms-of-service">Terms of Service</Link>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                    </Main>
                </StackWrapper>
            </div>
        );
    }
}

const PageMeta: React.FC = () => {
    const { t } = useTranslation();
    return (
        <Helmet>
            <title>{t('pages.home.title')}</title>
        </Helmet>
    );
};

export default Home;
