import * as React from 'react';
import classNames from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Navigation: React.FC<RouteComponentProps> = ({ location }) => {
    const active = React.useMemo(() => {
        console.log(location);
        if (location.pathname === '/') {
            return 'home';
        }
        return location.pathname;
    }, [location.pathname]);
    return (
        <NavStrip>
            <NavList>
                <NavItem
                    className={classNames({
                        active: active === 'home',
                    })}
                >
                    <Link to="/">Home</Link>
                </NavItem>
            </NavList>
        </NavStrip>
    );
};

const NavStrip = styled.nav`
    display: flex;
    width: 100%;
    color: #fefefe;
    margin-bottom: 20px;
`;

const NavList = styled.ol`
    display: flex;
    color: #fefefe;
    list-style: none;

    margin: 0;
    padding: 0;

    > li {
        margin-right: 10px;
    }
`;

const NavItem = styled.li`
    display: block;
    color: #fefefe;
    padding: 5px 2px;
    border-bottom: 2px solid transparent;
    transition: border 200ms ease-out;

    a,
    a:hover,
    a:active,
    a:visited {
        color: inherit;
        text-decoration: inherit;
        font-family: inherit;
        font-size: inherit;
    }
    &:hover,
    &.active {
        border-bottom-color: #fefefe;
    }
`;

export default withRouter(Navigation);
